.navbar {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #2d120a;

  position: fixed;
  top: 0;

  font-size: 2.3vw;
  text-align: left;
  padding-left: 2%;
  padding-top: 0%;
  margin: 0;

  /* box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.35); */
}

.title {
  font-family: "Georgia", sans-serif;
  font-size: 1.9em;
  text-align: left;
}
@font-face {
  font-family: "Jost-ExtraLight";
  src: url(../fonts/Jost/static/Jost-ExtraLight.ttf);
  font-weight: lighter;
}

.navbar a {
  color: #ffd966;
  text-decoration: none;
  margin: 0;
  opacity: 1;

  transition: padding 0.4s, opacity 0.15s;

  /* force default mouse pointer */
  cursor: default;
}
.navbar a:hover {
  opacity: 0.7;
  padding: 4%;
}
.navbar a:active {
  opacity: 0.45;
  text-decoration: none;
}