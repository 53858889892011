.page {
  font-size: 1.4vw;
  width: 80%;
  font-weight: lighter;
  padding-top: 3%;
  margin-left: 18%;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.page img {
  width: 100%;
  padding: 0;
  margin: 0;
}

.page::-webkit-scrollbar {
  display: none;
}

.page img {
  pointer-events: none;
}

/* home page specific */
.Home {
  width: 70%;
  margin: auto;
  height: 80vh;
}
.homeTable td {
  padding: 20px;
}
.homeText a {
  color: #ffd966;
}

/* code page specific */
.code {
  width: 70%;
  padding-top: 1vw;
  margin: auto;
}
.codeTable {
  width: 100%;
}
.codeTable td {
  padding: 20px;
}

/* photo specific */
.photo {
  width: 90%;
  margin-left: 5%;
}
.photoIntro {
  padding-top: 0.5vw;
  padding-right: 15vw;
  padding-bottom: 1vw;
  padding-left: 15vw;
}

/* 
.photoSlide {
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.6));
}
*/

/* music table specific */
.music {
  width: 80%;
  margin-left: 10%;
}
.music td {
  width: 50%;
}
.musicText {
  padding-top: 0;
  padding-right: 2vw;
  padding-bottom: 2vw;
  padding-left: 2vw;
}
.musicIframe {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 2vw;
  padding-left: 0;
  height: 50vh;
}
.music iframe {
  width: 100%;
  height: 43vh;
  border: none;
  /* filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.6)); */
}