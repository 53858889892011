.footer {
  width: 6%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #2d120a;

  position: fixed;
  right: 0px;
  padding-right: 0%;
  padding-top: 3%;

  /* box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.35); */
}

.footer a {
  color: #ffd966;
  text-decoration: none;
  margin: 0;
  opacity: 0.9;

  transition: padding-right 0.4s, opacity 0.1s;

  /* force default mouse pointer */
  cursor: default;
}
.footer a:hover {
  padding-right: 20%;
  opacity: 0.7;
}
.footer a:active {
  color: rgb(200, 200, 200);
  opacity: 0.45;
}

.logos {
  width: 70%;
  padding-left: 20%;
}
.logo img {
  width: 70%;
  opacity: 0.9;
  padding-bottom: 10%;
}