.App {
  text-align: center;
  font-family: "Georgia", sans-serif;
  user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

::-webkit-scrollbar {
  background: #2d120a;
  width: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d3b14a;
  border-radius: 10px;

}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8e7221;
}

@font-face {
  font-family: "Jost";
  src: url(./fonts/Jost/static/Jost-Light.ttf);
  font-weight: normal;
}

body {
  padding: 0;
  margin: 0;
  background: #2d120a;
  color: #ffd966;
  line-height: 1.3;
}